<template>
  <div class="dg-module-form" v-if="amReady">
    <div class="dg-module-container text-capitalize mb-2" v-if="formHeader">
      <h5>{{ this.getFormTitle }}</h5>
    </div>
    <div class="dg-module-container p-3">
      <el-row>
        <el-col :class="$langConfig.cssClass" :span="(!editMode)? 6 : 6">
          <dg-text-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"

              :field_span="19"
              :label_span="24"
              :translateLabel='false'
              field_mark="required"
              label="name"
              name="name"
              placeholder="Name"
              rules="required"></dg-text-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" :span="(!editMode)? 6 : 6">
          <dg-select-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"

              :field_span="19"
              :label_span="24"
              :options="$store.state['Time-EventType-api'].dropDownList"
              :translateLabel='false'
              :translateLabels="false"
              field_mark="required"
              label="Type"
              name="type_id"
              placeholder=""
              rules="required"></dg-select-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" :span="(!editMode)? 6 : 6">
          <dg-select-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"

              :field_span="19"
              :label_span="24"
              :options="frequentlySlots"
              :translateLabel='false'
              :translateLabels="false"
              @amUpdated="frequentlyUpdated"
              field_mark="optional"
              label="Frequently"
              name="schedule_frequently"
              placeholder=""
              rules="optional"></dg-select-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" :span="(!editMode)? 6 : 6">
          <dg-radio-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="19"
              :label_span="24"
              :options="radioOptions"
              :translateLabel="false"
              @amUpdated="isInternalUpdated"
              defaultValue="1"
              field_mark="required"
              label="Internal Event"
              name="is_internal"
              rules="required"></dg-radio-field>
        </el-col>
      </el-row>

      <el-row>
        <el-col :class="$langConfig.cssClass" :span="(!editMode)?  6 : 6">
          <dg-date-picker-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :defaultValue="this.selectedDateInfo.startStr"

              :field_span="19"
              :label_span="24"
              :translateLabel='false'
              field_mark="required"
              label="Start Date"
              name="date_start"
              placeholder=""
              rules="required">
            <template slot="field_note">
              <br><i>Selected Date is set by default</i>
            </template>
          </dg-date-picker-field>
        </el-col>


        <el-col :class="$langConfig.cssClass" :span="(!editMode)?  6 : 6">
          <dg-date-picker-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :defaultValue="currentActiveSemester.end_date"

              :field_span="19"
              :label_span="24"
              :translateLabel='false'
              field_mark="required"
              label="Until Date"
              name="date_end"
              placeholder=""
              rules="required">
            <template slot="field_note">
              <br><i>Semester End Date is set by default</i>
            </template>
          </dg-date-picker-field>
        </el-col>


        <el-col :class="$langConfig.cssClass" :span="(!editMode)? 6 : 6">
          <dg-time-picker-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"

              :field_span="19"
              :label_span="24"
              :options="timePickerOptions"
              :translateLabel='false'
              :translateLabels="false"
              @amUpdated="timePicker1Updated"
              field_mark="required"
              label="Start Time"
              name="start_time"
              placeholder=""
              rules="required"></dg-time-picker-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" :span="(!editMode)? 6 : 6">
          <dg-time-picker-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"

              :field_span="19"
              :label_span="24"
              :options="timePickerOptions2"
              :translateLabel='false'
              :translateLabels="false"
              field_mark="required"
              label="End Time"
              name="end_time"
              placeholder=""
              rules="required"></dg-time-picker-field>
        </el-col>
      </el-row>

      <el-row>
        <el-col :class="$langConfig.cssClass" :span="(!editMode)? 6 : 6" v-show="selectedIsInternalEvent == '1'">
          <dg-select-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"

              :field_span="19"
              :label_span="24"
              :options="$store.state['Education-Room-api'].dropDownList"
              :rules="(selectedIsInternalEvent == '1')? 'required' : 'optional'"
              :translateLabel='false'
              :translateLabels="false"
              field_mark="required"
              label="Room"
              name="room_id"
              placeholder=""></dg-select-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" :span="(!editMode)? 6 : 6" v-if="untilIsActivated">
          <dg-select-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"

              :field_span="19"
              :label_span="24"
              :allowCreate="false"
              :multiple="true"
              :options="weekDays"
              :translateLabel='false'
              :translateLabels="false"
              @amUpdated="repeatDaysUpdated"
              field_mark="optional"
              label="Repeat Days"
              name="repeat_days"
              placeholder=""
              rules="optional"></dg-select-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" :span="(!editMode)? 18 : 18" v-show="selectedIsInternalEvent == '0'">
          <dg-text-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :defaultValue="dataToBind.address"

              :field_span="21"
              :label_span="24"
              :rules="(selectedIsInternalEvent == '0')? 'required' : 'optional'"
              :translateLabel='false'
              field_mark="required"
              label="Address"
              name="address"
              placeholder=""></dg-text-field>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24" class="mt-2 border-top">
          <el-col :class="$langConfig.cssClass" :span="12" class="mt-4">
            <label class="error-field-validate-message text-capitalize"
                   v-show="!this.validData">{{
                (requestErrorMessage) ? requestErrorMessage : $ml.get('fields_do_not_match_the_required_criteria')
              }}</label>
          </el-col>
          <el-col :span="12" class="pt-3 text-right">

            <dg-button :click="moduleDeleteAction" class="mr-2" fieldSize="mini" icon="el-icon-delete" text="remove"
                       type="danger" v-if="editMode"></dg-button>

            <dg-button :click="cancelEditable" class="mr-2" fieldSize="mini" icon="el-icon-close" text="cancel"
                       type="warning" v-if="editable && editMode"></dg-button>
            <dg-button :click="changeEditable" fieldSize="mini" icon="el-icon-edit" text="edit" type="success"
                       v-if="!editable && editMode"></dg-button>

            <dg-button :click="createModule" :icon="actionButtonIcon" :loading="requestOn" :text="actionButtonLabel"
                       :translate="false"
                       :type="actionButtonType" fieldSize="mini"
                       v-if="editable"></dg-button>

            <!--<dg-confirm-button
                :icon="actionButtonIcon"
                :loading="requestOn"
                :onConfirm="createModule"
                :text="actionButtonLabel"
                :translate="false"
                :type="actionButtonType"
                fieldSize="mini"
                v-if="editable"></dg-confirm-button>-->
          </el-col>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import durationSlots from './durationSlots.json'
import frequentlySlots from './frequentlySlots.json'
import weekDays from './weekDays.json'

export default {
  props: {
    selectedDateInfo: {
      required: true
    }
  },
  computed: {
    ...$mapState('Education-Semester-api', ['currentActiveSemesterId', 'currentActiveSemesterName', 'currentActiveSemester']),
  },
  beforeMount() {
    this.getFormData()
  },
  data() {
    return {
      weekDays: weekDays,
      selectedIsInternalEvent: null,
      frequentlySlots: frequentlySlots,
      timePickerOptions: {
        start: '07:00',
        end: '21:00',
        step: '00:15',
        minTime: ''
      },
      timePickerOptions2: {
        start: '07:00',
        end: '21:00',
        step: '00:15',
        minTime: ''
      },
      durationSlotsOptions: durationSlots,
      selectedMajor: null,
      selectedCourse: null,
      selectedAgeRange: null,
      coursesByMajor: [],
      teachersBySelectedProperties: [],
      control: this,
      amReady: false,
      requestOn: false,
      editable: !this.editMode,
      untilIsActivated: false,
      requestErrorMessage: null,
      radioOptions: [
        {
          label: 'Yes',
          value: 1,
          link_label: ''
        },
        {
          label: 'No',
          value: 0,
          link_label: ''
        }
      ],
    }
  },
  methods: {
    repeatDaysUpdated(inputField) {
      let val = inputField.field.field_value
    },
    isInternalUpdated(inputField) {
      let val = inputField.field.field_value
      this.selectedIsInternalEvent = val
    },
    frequentlyUpdated(inputField) {
      let val = inputField.field.field_value

      if (val) {
        this.untilIsActivated = true
      } else {
        this.dataModel.repeat_days = null
        this.untilIsActivated = false
      }
    },
    timePicker1Updated(inputField) {
      let val = inputField.field.field_value
      this.timePickerOptions2.minTime = val
      this.resetField('end_time')
    },
    ...$mapActions('Time-EventType-api', {
      getEventTypeDropdownList: 'getDropDownList'
    }),
    ...$mapActions('Education-Room-api', {
      getEducationRoomDropDownlist: 'getDropDownList'
    }),
    getFormData() {
      let promises = []
      promises.push(this.getEventTypeDropdownList())
      promises.push(this.getEducationRoomDropDownlist())
      Promise.all(promises).then((values) => {
        this.amReady = true
        $vue.nextTick(() => {
          this.bindController()
        })
      })
    },
    async createModule() {
      this.validateFields().then(async result => {
        if (!result) {
          this.requestErrorMessage = null;
          return
        }

        this.requestOn = true
        let context = this;
        let apiMethod = (this.editMode) ? 'update' : 'create';

        /*let startTime = this.selectedDateInfo.startStr + "T" + this.dataModel.date_time_start_at
        let endTime = this.selectedDateInfo.endStr + "T" + this.dataModel.date_end*/

        let startDate = this.dataModel.date_start
        let endDate = this.dataModel.date_end

        let startTime = this.dataModel.start_time
        let endTime = this.dataModel.end_time
        let frequently = false

        let startDateTime = startDate + "T" + startTime
        let endDateTime = endDate + "T" + endTime

        /*this.dataModel.date_start = startDateTime
        this.dataModel.date_end = endDateTime*/

        let eventJson = {
          title: this.dataModel.name,
          start: startDateTime,
          end: endDateTime,
          extendedProps: {
            event_type_id: this.dataModel.type_id
          }
        }

        if (this.dataModel.schedule_frequently) {
          eventJson.rrule = {
            freq: this.dataModel.schedule_frequently,
            interval: 1,
            // byweekday: [ 'mo', 'fr' ],
            dtstart: startDateTime,
            until: this.dataModel.date_end
          }

          if (this.dataModel.repeat_days && this.dataModel.repeat_days.length) {
            eventJson.rrule.byweekday = this.dataModel.repeat_days
          }
        }

        this.dataModel.event_json = eventJson
        let apiParams = {data: {...this.dataModel}};
        if (this.editMode) apiParams.id = this.dataToBind.id;

        this.$store.dispatch('Time-Event-api/' + apiMethod, apiParams).then(response => {
          let entity = response.data
          this.amReady = false
          $services['notify']({
            type: 'success',
            title: (apiMethod == 'update') ? 'Updated' : 'Created',
            position: 'topRight',
          })

          $vue.nextTick(() => {
            context.amReady = true
            if (context.editMode) {
              if (context.onEditCallback) context.onEditCallback(response.data)
            } else {
              if (context.onCreateCallback) context.onCreateCallback(response.data)
            }
          })

        }).catch(error => {
          console.log('catch error', error)
          this.validData = false
          if (error.response.data.errorMessage) {
            $store.state['App-App-state'].generalDialogStatus = true
            $store.state['App-App-state'].generalDialogMessage = error.response.data.errorMessage
            $store.state['App-App-state'].generalDialogTitle = "Attention"

            this.requestErrorMessage = error.response.data.errorMessage
          } else {
            this.setfieldsErrorMessage({
              fieldsErrors: error.response.data
            });
          }
        }).finally(() => {
          this.requestOn = false
        })
      })
    }
  },
  mixins: [$mixins['fieldController'], $mixins['moduleForm']],
}
</script>
